import { selectCountriesState } from '../index';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CountriesState } from '../reducers/countries.reducer';
import { selectOrder } from '@search/store/selectors/search.selectors';
import { Country } from '@general-data/interfaces/country.interface';
import { selectAuthProfileCountry } from '@auth/store/selectors/profile.selectors';

export const selectAllCountries = createSelector(
  selectCountriesState,
  (state: CountriesState) => state && state.countries
);

export const selectAllCountriesSorted = createSelector(selectAllCountries, (countries) => {
  if (countries) {
    const _countries: Country[] = [...countries];
    return _countries.sort((a, b) => a.name.localeCompare(b.name));
  }
  return null;
});

export const selectHubsLoading = createSelector(
  selectCountriesState,
  (state: CountriesState) => state && state.loading
);
export const selectHubsError = createSelector(selectCountriesState, (state: CountriesState) => state && state.error);

export const selectCountryByCode = (
  isoAlpha3: string
): MemoizedSelector<object, Country | null | undefined, (s1: Country[] | null) => Country | null | undefined> =>
  createSelector(
    selectAllCountries,
    (countries) => countries && countries.find((country) => country.isoAlpha3 === isoAlpha3)
  );

export const selectSearchedOrderCountry = createSelector(selectOrder, selectAllCountries, (order, countries) => {
  if (!order || !countries) {
    return null;
  }
  return countries.find((country) => country.isoAlpha3 === order.country);
});

export const selectCitiesByCountryCode = (
  isoAlpha3: string
): MemoizedSelector<object, string[] | null, (s1: Country[] | null) => string[] | null> =>
  createSelector(selectAllCountries, (countries) => {
    const country = countries && countries.find((c) => c.isoAlpha3 === isoAlpha3);
    return country ? [...country.cities].sort() : null;
  });

export const selectUserCountry = createSelector(
  selectAuthProfileCountry,
  selectAllCountries,
  (profileCountry, countries) => {
    if (!profileCountry || !countries) {
      return null;
    }
    return countries.find((country) => country.isoAlpha3 === profileCountry);
  }
);
